@import url('https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oswald:400,500,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:600&display=swap');
@import url('styles.css');

/*                    
====================================================================
                          Commom Css Here [Reset] 
====================================================================*/

* {margin:0px; padding:0px; border:none; outline:none; }

body { font-family: 'Poppins', sans-serif; font-size:14px; color:#000000; line-height: 20px; font-weight:400; background:#ffffff; }

a{ text-decoration:none; cursor:pointer; outline: none; }

a:hover,a:focus,a:visited{text-decoration:none; outline:none; }

input,button,select,textarea{font-family: 'Poppins', sans-serif; }

ul,li{list-style:none; padding:0px; margin:0px; }

img { max-width: 100%; }

/*
====================================================================
                       Web Page Css Here
====================================================================*/
body {
  font-family: 'Hind';
  background: rgb(251 237 237);
}
.swal2-title {
  font-size: 20px !important;
  display: block;
  padding: 45px 45px 0px 45px !important;
}
.margin-top-0 {
  margin-top: 0px !important;
}
.margin-botton-20 {
  margin-bottom: 20px;
}
.container {
  max-width:1200px;
}
.box-shadow {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.dynamic-form .border-top-dark {
  border-top: 10px solid #eac7c7;
}
.main-heading {
  font-size: 24px;
  font-weight: 600;
}
::placeholder{
  font-size: 12px;
  color: #adadad;
}
.animated {
  min-height: 600px;
}
.animated .loader {
  width: 60px;
}
.bg_grey{
  background-color:#f9f9f9;
}
.error-message {
  color: red;
}
.min-h {
	min-height: 650px;
}
.form-heading-wrapper {
  display: flex;
  position: relative;
}
.form-subheading {
  font-size: 24px;
  font-weight: 600;
  width: 60%;
  word-break: break-word;
  margin: -15px 0px 15px -10px;
  padding: 0px 12px;
}
.btn-blocks {
  display: flex;
  height: auto;
  position: absolute;
  right: -10px;
  top: -25px;
  column-gap: 10px;
  justify-content: space-between;
}
.form-heading-wrapper .add-more {
  position: absolute;
  right: 0;
  top: 0;
}
.form-label {
  color: #393939;
  font-weight: 700;
  font-size: 22px;
  display: flex;
  line-height: 28px;
}
.form-value {
  font-size:  16px;
}
.form-check-label {
  font-size: 16px;
}
.mandatory-field {
  color:  red;
}
.form-group {
  /*border: 1px solid #f2f2f2;*/
  border-top: 5px solid #c5c5c5;
  border-radius: 5px;
  padding: 20px;
  background: #fff;
  margin: 0 0px 20px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.form-control-range {
  display: block;
  width: 100%;
}
.btn-container {
  display: inline-block;
  float: right;
  margin: -15px 0px 0px 0px;
}
.btn{
  padding: 5px 30px;
}
.divider {
  width:10px;
  height:auto;
  display:inline-block;
}
.navbar-nav .dropdown-menu {
  padding: 10px;
  margin: 15px 0px 0px -100px;
}
.dropdown .menus-list {
  color: white;
  cursor: pointer;
}
.navbar-nav .dropdown-menu {
  padding: 10px;
  margin: 15px 0px 0px -100px;
}

.navbar-nav .dropdown-menu li {
  padding: 5px;
  font-size: 16px;
}

.navbar-nav .dropdown-menu li:hover {
  font-weight: bold;
  color: #0051ca;
}

.navbar-nav .dropdown-menu li.active {
  font-weight: bold;
  color: #0051ca;
}

.list-menus {
  padding: 10px;
}

.list-menus li {
  padding: 5px;
  font-size: 16px;
}

.list-menus li:hover {
  font-weight: bold;
  color: #0051ca;
}

.list-menus li.active {
  font-weight: bold;
  color: #0051ca;
}

.location-content-wrapper {
  font-size: 16px;
  margin-top:  20px;
}
.error-location-container .location-content-wrapper .noteMessage {
  color: red;
}
.success-location-container .location-content-wrapper .noteMessage {
  color: #00bc00;
}
.location-content-wrapper .noteMessage label {
  font-weight: bold;
}
.modal-dialog .modal-title {
  font-size: 22px;
  font-weight: 600;
}
.modal-dialog .close {
  background-color: #e5e5e5;
  padding: 5px 10px;
  border-radius: 50%;
}
.modal-dialog .modal-footer {
  padding: 10px 25px;
}
.location-access-instructions {
  margin-top: 20px;
}
.location-access-instructions .noteMessage {
  color: #0d6efd;
  cursor: pointer;
  font-size: 14px;
}
.location-instruction-modal .instruction-list li {
  list-style: upper-roman;
  padding: 0px;
  margin: 15px 0px 0px 25px;
}
.user-login-modal .instruction-list li {
  list-style: upper-roman;
  padding: 0px;
  margin: 15px 0px 0px 25px;
}
.signature-modal-lg {
  width: 100%;
  max-width: 875px;
}
.signature-modal-lg .signature {
  display: block;
  margin: 0px auto;
  border: 1px solid #cccccc;
  width: 100%;
  height: 400px;
}
.inline-b {
  display: inline-block;
  position: relative;
}
.webcam-modal {
  width: 100%;
  max-width: 680px;
}
.webcam-modal .webcam-video-canvas {
  display: block;
  margin: 0px auto;
  width: 100%;
  height: auto;
}
.invalid-response-error {
  color: #ff0018;
  font-size: 14px;
}
.invalid-feedback {
  color: #ff0018;
  font-size: 14px;
}
.breadcrumb {
  font-size: 22px;
  font-weight: 600;
  width: 35%;
  padding: 12px;
  border-radius: 8px;
  margin: -15px 0px 35px -10px;
}
.bg-white {
  background: white;
}
.help-icon {
  color: red;
}
.help-icon:hover {
  cursor: pointer;
}
.popover-body {
  padding: 1rem 1rem;
  color: #212529;
  background: #fdff8a;
}
.show-all-records-modal {
  min-width: 800px;
  max-width: 1100px;
}
.terms-condition-modal {
  min-width: 600px;
  max-width: 900px;
}
.all-fields-shown-wrap th {
  background: #f7f7f7;
  font-size: 16px;
}
.links-wrap {
  margin-top: 15px;
}

.links-wrap .a-link {
  margin-bottom: 5px;
  font-size: 16px;
}

.mt-65 {
  margin-top: 0;
}

.mt-90 {
  margin-top: 0;
}

.tc-agree-block {
  display: flex;
}

.tc-agree-block .content-wrapper {
  margin-left: 5px;
}

.word-break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.i-heading {
  font-size: 26px;
}

.i-content {
  margin-top: 35px;
  font-size: 18px;
  line-height: 24px;
}

.address-info {
  line-height: 14px;
  margin-top: 35px;
  font-size: 16px;
}

.address-info p {
  line-height: 22px;
}

.address-info label {
  font-weight: bold;
}

.feature-info {
  line-height: 14px;
  margin-top: 35px;
  font-size: 16px;
}

.feature-info h5 {
  font-weight: bold;
}

.feature-info ul li {
  line-height: 22px;
  list-style-type: circle;
  margin-left: 25px;
}


.sitelink-info {
  line-height: 14px;
  margin-top: 35px;
  font-size: 16px;
}

.sitelink-info p {
  line-height: 22px;
}

.sitelink-info label {
  font-weight: bold;
}

.our-info {
  line-height: 14px;
  margin-top: 35px;
  font-size: 16px;
}

.our-info p {
  line-height: 22px;
}

.our-info label {
  font-weight: bold;
}

.floater-field datalist {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  writing-mode: vertical-lr;
  width: 100%;
}

.floater-field option {
  padding: 0;
  transform: rotate(-90deg);
  font-size: 12px;
}

.floater-field input[type="range"] {
  width: 100%;
  margin: 0;
  accent-color: #643e3e;
}

#google_translate_element {
  position: relative;
}

#google_translate_element .goog-te-gadget {
  position: absolute;
  right: 5px;
  top: 0;
  color:  #ffffff;
}

#google_translate_element .goog-te-gadget a {
  color:  #ffffff;
  font-weight: normal;
}

.dynamicform-background {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-attachment: fixed !important;
  background-size: 100% 100% !important;
}

.form-position-default .px-7 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.form-position-left .px-7 {
  padding-right: 24rem !important;
  padding-left: 0rem !important;
}

.form-position-right .px-7 {
  padding-right: 0rem !important;
  padding-left: 24rem !important;
}

.form-position-center .px-7 {
  padding-right: 12rem !important;
  padding-left: 12rem !important;
}

.form-position-default .px-9 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.form-position-left .px-9 {
  padding-right: 24rem !important;
  padding-left: 0rem !important;
}

.form-position-right .px-9 {
  padding-right: 0rem !important;
  padding-left: 24rem !important;
}

.form-position-center .px-9 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.form-position-top .py-dynamic {
  padding-top: 8rem !important;
  padding-bottom: 0rem !important;
}

.form-position-bottom .py-dynamic {
  padding-top: 0rem !important;
  padding-bottom: 9rem !important;
}

/*Blocks of questions are NOT transparent.*/
.bg-transparency1 .bg-light {
  background-color: #f8f9fa00 !important;
}

.bg-transparency1 .box-shadow {
  box-shadow: none !important;
}

.bg-transparency1 .form-top-border {
  border-top: 0 !important;
}

.bg-transparency1 .form-group {
  background: #fff !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.bg-transparency1 .btn-container {
  margin: -5% 4% 0% 0% !important;
}

/*Blocks of questions are transparent.*/
.bg-transparency2 .bg-light {
  background-color: #f8f9fa00 !important;
  /*background-color: #ffffff4f !important;*/
}

.bg-transparency2 .box-shadow {
  box-shadow: none !important;
}

.bg-transparency2 .form-top-border {
  border-top: 0 !important;
}

.bg-transparency2 .form-group {
  background: #ffffff54 !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.bg-transparency2 .btn-container {
  margin: -5% 4% 0% 0% !important;
}

/*Blocks of questions are transparent + Question Block Color Header Line Absent + Background Block Present*/
.bg-transparency3 .bg-light {
  background-color: #ffffff80 !important;
}

.bg-transparency3 .box-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bg-transparency3 .form-top-border {
  border-top: 0 !important;
}

.bg-transparency3 .form-group {
  border-top: 0 !important;
  background: #ffffff1f !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}


/*Blocks of questions NOT Present + Background Block Present*/
.bg-transparency4 .bg-light {
  background-color: #ffffff80 !important;
}

.bg-transparency4 .box-shadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.bg-transparency4 .form-top-border {
  border-top: 0 !important;
}

.bg-transparency4 .form-group {
  border-top: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

/*Blocks of questions NOT Present + Background Block NOT Present*/
.bg-transparency5 .bg-light {
  background-color: #f8f9fa00 !important;
}

.bg-transparency5 .box-shadow {
  box-shadow: none !important;
}

.bg-transparency5 .form-top-border {
  border-top: 0 !important;
}

.bg-transparency5 .form-group {
  border-top: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.bg-transparency5 .btn-container {
  margin: -5% 4% 0% 0% !important;
}

.width-100 {
  width: 100% !important;
}

.signature {
  display: block;
  margin: 0px auto;
  border: 1px solid #cccccc;
  width: 100%;
  height: 200px;
}

.h-block {
  display: none;
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
}

ul.fduk-list {
  list-style-type: circle;
}

ul.fduk-list li {
  list-style: circle;
  padding: 2.5px;
  margin: 5px 15px;
}

@media (max-width:480px){
  .form-heading-wrapper {
    display: block;
    position: relative;
  }
  .form-subheading {
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    word-break: break-word;
    margin: -15px 0px 15px -10px;;
  }
  .btn-blocks {
    display: flex;
    height: auto;
    width: 100%;
    position: initial;
    right: 0px;
    top: 0px;
    margin: 15px 0px;
    column-gap: 10px;
    justify-content: space-between;
  }
  .show-all-block {
    margin-bottom: 15px;
  }
  .show-all-block .btn-lg {
    font-size: 18px;
    padding:  5px 25px;
  }
  .delete-block {
    margin-bottom: 15px;
  }
  .delete-block .btn-lg {
    font-size: 18px;
    padding:  5px 25px;
  }
  .list-block {
    margin-bottom: 15px;
  }
  .list-block .btn-lg {
    font-size: 18px;
    padding:  5px 25px;
  }
  .show-all-records-modal {
    min-width: 300px;
    max-width: 450px;
  }
  .list-records-modal {
    min-width: 300px;
    max-width: 450px;
  }
  .terms-condition-modal {
    min-width: 300px;
    max-width: 450px;
  }
  .btn-container {
    display: flex;
    float: none;
    margin: 0;
  }
  .btn-container .btn-lg {
    width: 100%;
    font-size: 18px;
    padding: 10px;
  }
  .mt-65 {
    margin-top: 65px;
  }
  .mt-90 {
    margin-top: 90px;
  }
  .breadcrumb {
    font-size: 22px;
    width: 100%;
    margin: -15px 0px 40px -10px;
  }
  .employee-breadcrumb {
    font-size: 16px;
  }
  .menu-dropdown-block {
    position: relative;
  }

  .menu-dropdown-block a {
    position: absolute;
    top: 35px;
    right: 0px;
    background: #ffffff;
    border-radius: 5px;
    font-size: 12px;
  }

  .menu-dropdown-block .dropdown-menu.show {
    left: -65px !important;
    font-size: 12px;
  }
  .user-list-heading {
    position: relative;
    padding: 15px 0px 10px 0px;
    margin-left: -15px;
  }

  .user-list-heading .main-heading {
    margin-left: 0;
  }

  .user-list-heading .search-bar {
    margin-left: 0;
    width: 100% !important;
    margin-top: 20px;
    margin-bottom: -10px;
    position: initial;
  }

  .uv-btn-wrapper .btn-next {
    width: 100%;
  }

  .uv-btn-wrapper .btn-vmf {
    width: 100%;
    margin-top: 10px;
    margin-left: 0px;
  }
  .webcam-modal {
    width: 100%;
    overflow: scroll;
    max-width: 370px;
  }
  .webcam-modal .webcam-video-canvas {
    display: block;
    margin: 0px auto;
    width: 100%;
    height: auto;
  }

  .signature-modal-lg {
    width: auto;
    max-width: auto;
  }
  .signature-modal-lg .signature {
    display: block;
    margin: 0px auto;
    border: 1px solid #cccccc;
    width: 100%;
    height: 250px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 319px)  {
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .rounded-3 {
    border-radius: 0.3rem !important;
  }
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-5 {
    margin-bottom: 3rem !important;
  }
  .form-label {
    min-width: 260px;
    line-height: 28px;
  }
  .breadcrumb {
    font-size: 22px;
    width: 100%;
    margin: -15px 0px 40px -10px;
  }
  .form-position-default .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-left .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-right .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-center .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-default .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-left .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-right .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-center .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-top .py-dynamic {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .form-position-bottom .py-dynamic {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .webcam-modal {
    width: 100%;
    overflow: scroll;
    max-width: 370px;
  }
  .webcam-modal .webcam-video-canvas {
    display: block;
    margin: 0px auto;
    width: 100%;
    height: auto;
  }

  .signature-modal-lg {
    width: auto;
    max-width: auto;
  }
  .signature-modal-lg .signature {
    display: block;
    margin: 0px auto;
    border: 1px solid #cccccc;
    width: 100%;
    height: 250px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 480px)  {
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-5 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-6 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-7 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }
  .rounded-3 {
    border-radius: 0.3rem !important;
  }
  .mb-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  .mb-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-5 {
    margin-bottom: 3rem !important;
  }
  .form-label {
    min-width: 260px;
    line-height: 28px;
  }
  .breadcrumb {
    font-size: 22px;
    width: 100%;
    margin: -15px 0px 40px -10px;
  }
  .form-position-default .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-left .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-right .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-center .px-7 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-default .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-left .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-right .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-center .px-9 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .form-position-top .py-dynamic {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .form-position-bottom .py-dynamic {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .webcam-modal {
    width: 100%;
    overflow: scroll;
    max-width: 370px;
  }
  .webcam-modal .webcam-video-canvas {
    display: block;
    margin: 0px auto;
    width: 100%;
    height: auto;
  }

  .signature-modal-lg {
    width: auto;
    max-width: auto;
  }
  .signature-modal-lg .signature {
    display: block;
    margin: 0px auto;
    border: 1px solid #cccccc;
    width: 100%;
    height: 250px;
  }
}